import client from '../apiService';

class InternationalDiscountService{
    async GetDiscounts(searchTerm: string, page: number){
        const url = `InternationalDiscount/GetInternationalDiscounts?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
    
    createDiscount(discounts: object){
        const url = `InternationalDiscount/CreateInternationalDiscount`;
        return client.post(url, discounts, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    async GetSegmentation(){
        const url = `InternationalDiscount/GetSegmentation`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    async GetInactiveDiscounts(searchTerm: string){
        const url = `InternationalDiscount/GetInactiveInternationalDiscounts?searchTerm=${searchTerm}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }
    deactivateDiscount(discountId: number){
        const url = `InternationalDiscount/DeactivateDiscount?discountId=${discountId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateDiscount(discountId: number){
        const url = `InternationalDiscount/ActivateDiscount?discountId=${discountId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    
}

export default new InternationalDiscountService();