






























































































































































































































































import { Component, Vue, Watch} from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
import Navbar from '@/components/common/Navbar.vue';
import DataTable from '@/components/common/Tables/DataTable.vue';
import SideDrawer from '@/components/common/SideDrawer.vue';
import TextButton from '@/components/common/Buttons/TextButton.vue'
import { ILink, ITableHeader } from '@/interfaces/common';
import {IDiscount, ISegmentation} from '@/interfaces/internationalDiscount';
import Illustration from '@/components/common/Illustration.vue';
import InternationalDiscountService from '@/services/InternationalDiscountService';
import Snackbar from '@/components/common/Snackbar.vue'
import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
import SearchBar from "@/components/common/SearchBar.vue";


@Component({
    components: {
    SearchBar,
    Navbar,
    DataTable,
    SideDrawer,
    Illustration,
    DatePicker,
    Snackbar,
    ActionablePopup,
    TextButton,
  }
})

export default class Create extends Vue {
 private description: string ="No Discounts Found"
 private subdescription: string ="Please create Discounts to begin with"
 private showDiscountList: boolean = true
 private DiscountLoading: boolean = false
 private createDiscount: boolean = true
 private sideDrawer: boolean = false
 private timeout !: NodeJS.Timeout
 private valid: boolean = false
 private message: string = '';
 private snackbar: boolean = false
 private sidedrawerTitle: string = "Add International Discount"
 private totalDiscounts: number = 0
 private editablePayout: boolean=true;
 private menuItems: Array<ILink> = []
 private pages: number = 0
 private discountSearch: string = ""
 private page: number = 1
  private selectedDiscountId: number = 0
  private selectedActionMenu: number = -1
 private creatingOrViewingDiscount: boolean = false;
 private segmentations: Array<ISegmentation> =[]
 private discounts: Array<IDiscount> = []
 private inactiveDiscounts: boolean = false
 private snackbarColor: string = "success"
 private viewItem: IDiscount = {
            id: 0,
            discountName : '',
            startDate: '',
            endDate: '',
            firstLevelDiscount: '',
            secondLevelDiscount: '',
            segmentation:'',
            firstLevelDiscount_IsEditable:false,
            firstLevelDiscount_AutoApplied:false,
            secondLevelDiscount_IsEditable:false,
            secondLevelDiscount_AutoApplied:false,
            foC_Applicable:false,
            foC_Mandatory:false,
            isDiscountAppliedatProductLevel:true,
            focPayoutPercentage:''
        }
        private defaultItem: IDiscount = {
            id: 0,
            discountName : '',
            startDate: '',
            endDate: '',
            firstLevelDiscount: '',
            secondLevelDiscount: '',
            segmentation:'',
            firstLevelDiscount_IsEditable:false,
            firstLevelDiscount_AutoApplied:false,
            secondLevelDiscount_IsEditable:false,
            secondLevelDiscount_AutoApplied:false,
            foC_Applicable:false,
            foC_Mandatory:false,
            isDiscountAppliedatProductLevel: true,
            focPayoutPercentage:''
        }
        



 private tableHeaders: Array<ITableHeader> = [
    {
      text: "Name",
      value: "discountName",
      sortable: true,
      default: ''
    },
    {
      text: "Start Date",
      value: "startDate",
      sortable: false,
      default: ''
    },
    {
      text: "End Date",
      value: "endDate",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Segmentation",
      value: "segmentation",
      sortable: false,
      default: ''
    },
    {
      text: "First Level Discount(%)",
      value: "firstLevelDiscount",
      sortable: false,
      default: '0'
    },
    {
        text: "Second Level Discount(%)",
        value: "secondLevelDiscount",
        sortable: false,
        default: '0'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
  ]  
 private confirmationPopup: boolean = false
 private confirmationPopupTitle: string = ""
 private confirmationPopupSubtitle: string = ""
 private confirmationPopupText: string = ""
 private cancelBtnText: string = ""
 private confirmBtnText: string = ""

  

get formValidate(): Vue &{validate: () => boolean}{
            return this.$refs.form as Vue & {validate: () => boolean}
}
get formReset(): Vue &{reset: () => void}{
            return this.$refs.form as Vue & {reset: () => void}
 }
get formResetValidation(): Vue &{resetValidation: () => void}{
            return this.$refs.form as Vue & {resetValidation:() => void}
}

@Watch('discountSearch')
        searchVan(newVal: string){
            if(newVal && newVal.length > 2){
                this.timeout = setTimeout(() => {
                    this.inactiveDiscounts ? this.getInactiveDiscounts() :  this.getDiscounts(newVal,1);
                }, 1000);
            }else if(newVal.length == 0){
                this.timeout = setTimeout(() => {
                    this.inactiveDiscounts ? this.getInactiveDiscounts() : this.getDiscounts("",1);
                }, 1000);
            }
        }

 public openDrawer(value: string): void{
            this.sidedrawerTitle = this.createDiscount ? 'Fill Discount Details': 'View Discount Details';
            this.clearForm();
            this.sideDrawer = true;
            this.formResetValidation.resetValidation();
 }

public closeSideDrawer(value: boolean): void{
            this.hideWindowScrollBar(value);
            this.sideDrawer = value;
        }
        
  public async getDiscounts(searchTerm: string, page: number): Promise<boolean>{
            this.DiscountLoading = true;
            return new Promise<boolean>(res => {
                InternationalDiscountService.GetDiscounts(this.discountSearch,page).then((response) => {
                    this.discounts = response.data.discounts;
                    this.pages = response.data.pages;
                    this.totalDiscounts = response.data.total;
                    this.DiscountLoading = false;
                    if(this.discounts.length > 0){
                        this.showDiscountList = true;
                    }else{
                        this.showDiscountList = false;
                        this.description = this.discounts.length == 0 ? "No Discounts Found" : "No matching result found" ;
                        this.subdescription = this.discounts.length == 0 ? "Please create a Discount to begin with" : "No Discounts Found ";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.DiscountLoading = false;
                })
            })
        }
  public showCommunicationError(err : any) {    
            this.snackbarColor = "red";
            this.snackbar = true;
            this.message = window.navigator.onLine ? `Some Error occured` : `Cannot reach server at the moment`;
        }
  private async GetMenuActions(): Promise<void> {
                this.menuItems = [
                    {
                        Text: "DEACTIVATE",
                        Color: "red"
                    }];
        }

  public createNewDiscount(): void{
            let value = Object.assign({}, this.viewItem);
            if(value.foC_Mandatory==false && value.foC_Applicable== false){
              value.focPayoutPercentage='';
            }
            this.creatingOrViewingDiscount = true;
            InternationalDiscountService.createDiscount({
                id:value.id,
                discountName : value.discountName,
                startDate: value.startDate,
                endDate: value.endDate,
                firstLevelDiscount: value.firstLevelDiscount,
                secondLevelDiscount: value.secondLevelDiscount,
                segmentation:value.segmentation,
                firstLevelDiscount_IsEditable:value.firstLevelDiscount_IsEditable,
                firstLevelDiscount_AutoApplied:value.firstLevelDiscount_AutoApplied,
                secondLevelDiscount_IsEditable:value.secondLevelDiscount_IsEditable,
                secondLevelDiscount_AutoApplied:value.secondLevelDiscount_AutoApplied,
                foC_Applicable:value.foC_Applicable,
                foC_Mandatory:value.foC_Mandatory,
                isDiscountAppliedatProductLevel: value.isDiscountAppliedatProductLevel,
                focPayoutPercentage: value.focPayoutPercentage
            }).then((response) => {
                this.snackbarColor = "success";
                this.snackbar = true;
                this.message = "Discount Created Successfully";
                this.showDiscountList = true;
                this.creatingOrViewingDiscount = false;
                this.closeSideDrawer(false);
                this.clearForm();
                this.refreshDiscountList();
                 }).catch((error) => {
               this.creatingOrViewingDiscount = false;
                if(error.response.status == 400){
                    this.snackbarColor = "red";
                    this.snackbar = true;
                    this.message = "Please Fill Required(*)/Mandatory Field";
                }
            })
            }
  public viewDiscount(value: IDiscount): void{
            this.createDiscount = false;
            var valObj = this.segmentations.filter(function(elem){
            if(elem.displayName.toString() == value.segmentation.toString()) return elem.displayName;
            });
            this.openDrawer('');
              this.viewItem = Object.assign({}, {
                id:value.id,
                discountName : value.discountName,
                startDate:value.startDate,
                endDate:value.endDate,
                firstLevelDiscount: value.firstLevelDiscount,
                secondLevelDiscount: value.secondLevelDiscount,
                segmentation:valObj[0].segmentation,
                firstLevelDiscount_IsEditable:value.firstLevelDiscount_IsEditable,
                firstLevelDiscount_AutoApplied:value.firstLevelDiscount_AutoApplied,
                secondLevelDiscount_IsEditable:value.secondLevelDiscount_IsEditable,
                secondLevelDiscount_AutoApplied:value.secondLevelDiscount_AutoApplied,
                foC_Applicable:value.foC_Applicable,
                foC_Mandatory:value.foC_Mandatory,
                isDiscountAppliedatProductLevel:value.isDiscountAppliedatProductLevel,
                focPayoutPercentage:value.focPayoutPercentage
            })
          }
  public menuAction(discount: IDiscount, commandIndex: number){
           this.selectedDiscountId = discount.id;
           this.selectedActionMenu = commandIndex;
           switch (this.menuItems[commandIndex].Text) {
            case "DEACTIVATE": 
                        this.confirmationPopupTitle = "Confirm Deactivation";
                        this.confirmationPopupSubtitle = "Are you sure you wish to deactivate the " + discount.discountName + " Discount?";
                        this.confirmationPopupText = "";
                        this.cancelBtnText = "Cancel";
                        this.confirmBtnText = "Confirm";
                        this.confirmationPopup = true;
                    break;
                default: console.log("Unhandled menuAction")
            }
        }
    public activateDiscount(discount: IDiscount){
            this.selectedDiscountId = discount.id;
            this.confirmationPopupTitle = "Confirm Activation";
            this.confirmationPopupSubtitle = discount.discountName+" discount will be activated.";
            this.confirmationPopupText = "";
            this.cancelBtnText = "Keep Inactive";
            this.confirmBtnText = "Activate";
            this.confirmationPopup = true;
        }
    public async getInactiveDiscounts(): Promise<boolean>{
            this.DiscountLoading = true;
            return new Promise<boolean>(res => {
                InternationalDiscountService.GetInactiveDiscounts(this.discountSearch).then((response) => {
                    this.discounts = response.data.discounts;
                    this.pages = response.data.pages;
                    this.totalDiscounts = response.data.total;
                    this.DiscountLoading = false;
                     if(this.discounts.length > 0){
                        this.showDiscountList = true;
                    }else{
                        this.showDiscountList = false;
                        this.description = this.discounts.length == 0 ? "No Discounts Found" : "No matching result found" ;
                        this.subdescription = this.discounts.length == 0 ? "Please create a Discount to begin with" : "No Discounts Found ";
                    }
                    res(true);
                }).catch((error) => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.DiscountLoading = false;
                })
            })
        }
    public switchDiscounts(){
     if(this.inactiveDiscounts){
     this.tableHeaders = [
      {
      text: "Name",
      value: "discountName",
      sortable: true,
      default: ''
    },
    {
      text: "Start Date",
      value: "startDate",
      sortable: false,
      default: ''
    },
    {
      text: "End Date",
      value: "endDate",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Segmentation",
      value: "segmentation",
      sortable: false,
      default: ''
    },
    {
      text: "First Level Discount(%)",
      value: "firstLevelDiscount",
      sortable: false,
      default: '0'
    },
    {
        text: "Second Level Discount(%)",
        value: "secondLevelDiscount",
        sortable: false,
        default: '0'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getInactiveDiscounts();
    }
    else{
    this.tableHeaders = [
      {
      text: "Name",
      value: "discountName",
      sortable: true,
      default: ''
    },
    {
      text: "Start Date",
      value: "startDate",
      sortable: false,
      default: ''
    },
    {
      text: "End Date",
      value: "endDate",
      sortable: false,
      default: 'NA'
    },
    {
      text: "Segmentation",
      value: "segmentation",
      sortable: false,
      default: ''
    },
    {
      text: "First Level Discount(%)",
      value: "firstLevelDiscount",
      sortable: false,
      default: '0'
    },
    {
        text: "Second Level Discount(%)",
        value: "secondLevelDiscount",
        sortable: false,
        default: '0'
    },
    {
      text: "Actions",
      value: "actions",
      sortable: false,
      default: ''
    }
    ];
    this.getDiscounts(this.discountSearch,1);
    }
  }
  public changePage(value: number): void {
        this.page = value;
       this.inactiveDiscounts ? this.getInactiveDiscounts() : this.getDiscounts(this.discountSearch, value);
  }

  public async GetSegmentation(): Promise<boolean>{
            this.DiscountLoading = true;
           return new Promise<boolean>(res => {
                InternationalDiscountService.GetSegmentation().then((response) => {
                    this.segmentations = response.data.segmentation;
                    this.DiscountLoading = false;
                    res(true);
                }).catch((error) : any => {
                    this.showCommunicationError(error);
                    console.log(error);
                    this.DiscountLoading = false;
                })
            })
        }
     public clearForm(): void {
      this.viewItem = Object.assign({}, this.defaultItem);
    }
     private hideWindowScrollBar(hideIt: boolean) {
         window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
      public actionablePopup(){
            this.snackbar = true;
            this.confirmationPopup = false;
            this.showDiscountList = true;
        } 
    public closeSnackbar(value: boolean): void {
    this.snackbar = value;
    }
    private createOrViewDiscount():void{
            if(!this.creatingOrViewingDiscount)
            this.createDiscount ? this.createNewDiscount() : this.viewDiscount(this.viewItem);
        }
  public async refreshDiscountList(){
     this.inactiveDiscounts ? await this.getInactiveDiscounts() : await this.getDiscounts(this.discountSearch,1);
      }
  public confirmAction(value: any):void{
            if(this.inactiveDiscounts){
                InternationalDiscountService.activateDiscount(value).then((res) => {
                    this.snackbarColor = "success";
                    this.message = "Changes Saved Successfully";
                    this.actionablePopup();
                    this.switchDiscounts();
                }).catch((err: any) => {
                    this.showCommunicationError(err);
                    console.log(err);
                })
            }
            else{
                InternationalDiscountService.deactivateDiscount(value).then((res) => {
                    this.snackbarColor = "red";
                    this.message = "Discount deactivated and moved to inactive list";
                    this.actionablePopup();
                    this.refreshDiscountList();
                }).catch((err) => {
                    this.showCommunicationError(err);
                    console.log(err);
                })
            }
        } 
  async created(){
            this.DiscountLoading = true;    
            this.getDiscounts(this.discountSearch,1);
            this.GetSegmentation();
            this.GetMenuActions().then(_ =>_);
            }
}
